import { configureStore } from "@reduxjs/toolkit";
import { loadingReducer } from "./LoadingSlice";
import { MainReducer } from "./MainSlice";

const reduxStore = configureStore({
  reducer: {
    main: MainReducer,
    loader: loadingReducer,
  }
});

export {reduxStore}