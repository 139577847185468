import '@fontsource/inter';
import '@fontsource/inter/800.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/500.css';
import './src/styles/global.css';

import React from 'react';
import {reduxStore} from './src/lib/ReduxStore';
import {Provider} from 'react-redux';

export const wrapRootElement = ({element}) => {
  return <Provider store={reduxStore}>{element}</Provider>;
};



