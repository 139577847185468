export enum LOADING {
  ALL_DATA = 1,
  LAUNCH_STUDY = 2,
}
export const ONBOARDING_STATE_MAP = {
  SignedUp: 1,
  HasIntegrated: 2, // Plugin Installed
  HasImplemented: 3, // Recording Triggered || User Identified
  ClipWatched: 4,
};


export const isOnboardingStateBefore = (state:string, checkLevel:number):boolean => {
  return (ONBOARDING_STATE_MAP[state] || 0) < checkLevel;
}