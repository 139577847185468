import { initializeApp } from "firebase/app";
import {getFunctions, httpsCallable, connectFunctionsEmulator} from "firebase/functions";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import {getAuth, connectAuthEmulator} from "firebase/auth";
import {auth as firebaseuiAuth} from 'firebaseui';

const firebaseConfig = {
  apiKey: "AIzaSyCPThsyWEQLuDdjCwA9tG0dJMka4uD29L4",
  authDomain: "vlly-unity.firebaseapp.com",
  projectId: "vlly-unity",
  storageBucket: "vlly-unity.appspot.com",
  messagingSenderId: "544503514429",
  appId: "1:544503514429:web:d4cd2c31e0fa9915917154",
  measurementId: "G-T71GBJTPS2"
};


const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app);
const functions = getFunctions(app);
const firebaseAuth = getAuth(app);

if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
  console.log('Loading from local emulator');
  connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectAuthEmulator(firebaseAuth, 'http://localhost:9099');
}

let firebaseUI;
if (typeof window !== 'undefined') {
  firebaseUI = new firebaseuiAuth.AuthUI(firebaseAuth);
  console.log(firebaseUI);
}

const getFirebaseFunction = (name:string) => {
  return httpsCallable(functions, name);
}

export {firestore, getFirebaseFunction, firebaseUI, firebaseAuth};