/**
 * Currently holds all the data that would be in this protoype. 
 */
import { createSlice } from "@reduxjs/toolkit";

import { navigate } from "@reach/router";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { LOADING } from './contants';
import { firebaseAuth, firestore, getFirebaseFunction } from "./FirebaseConfig";
import { startLoading, stopLoading } from "./LoadingSlice";


const initialState = {
  allData: null,
  metadata: {
    companyName: null,
    apiKey: 'apiKey'
  },
  // Watch Page
  currentVideoItem: null,
  videoList: null,
  videoIdStudyIdMap: null,
  studyMetadata: null,

  //
  studies: [],

  //
  userInfo: {
    name:''
  }
};

export const MainSlice = createSlice({
  name: 'MainSlice',
  initialState,
  reducers: {
    setAllData: (state, action) => {
      state.allData = action.payload;
      state.metadata = action.payload.metadata;
      // TODO: this should be done in a separte action
      state.studies = translateStudies(action.payload.studies);
    },
    setCurrentVideoItem: (state, action) => {
      state.currentVideoItem = action.payload;
    },
    setVideoList: (state, action) => {
      state.videoList = action.payload;
    },
    setStudyMetadata: (state, action) => {
      state.studyMetadata = action.payload;
    },
    setUserDisplayName: (state, action) => {
      state.userInfo.name = action.payload;
    }

  }
});

export const {setAllData, setCurrentVideoItem, setVideoList, setStudyMetadata, setUserDisplayName} = MainSlice.actions;
export const MainReducer = MainSlice.reducer;


export const onAuthStateChangedPromise = () => {
  return new Promise((res, rej) => {
    firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        res(user);
      } else {
        rej();
      }
    });
  });
}


export const initialize =  () => {
  return async (dispatch, getState) => {
    dispatch(startLoading(LOADING.ALL_DATA));
    let data; 
    try {
      data = await getData();
    } catch (error: any) {
      if (error.cause === 'no_auth_user') {
        window.location.href='/signup';
      } else if (error.cause === 'invalid_api_key') {
        window.location.href='/onboarding';
      }
      return;
    }

    const videoIdStudyIdMap = {};

    dispatch(setUserDisplayName(firebaseAuth.currentUser?.displayName));
    dispatch(setAllData(data));
    dispatch(stopLoading(LOADING.ALL_DATA));
  };
}

export const loadWatchPage = (clipId) => {
  return async (dispatch, getState) => {
    const s = getState();
    const studyId = getState().main.allData.derived.clipIdStudyIdMap[clipId];
    dispatch(setVideoList(getState().main.allData.studies[studyId].clips));
    dispatch(setStudyMetadata(getState().main.allData.studies[studyId].metadata));
  }
}


/**
 * Gets the data from the firestore database.
 * 
 * We have to manually rebuild the subcollection because firestore doesn't support nested collections. 
 * @returns 
 */
export const getData = async () => {
  let currentUser;
  try {
    currentUser = await onAuthStateChangedPromise();
  } catch (error) {
    throw Error('There is no auth user', {cause: 'no_auth_user'});
  }

  const userIdToken = await currentUser.getIdTokenResult(true);
  // const userIdToken = await currentUser.getIdTokenResult(true);
  const apiKey = userIdToken.claims.apiKey;
  const dataRef = doc(firestore, `data/${apiKey}`);
  const dataSnap = await getDoc(dataRef);

  if (!dataSnap.exists()) {
    throw new Error('Invalid API Key', {cause: 'invalid_api_key'});
  }

  const data = dataSnap.data();


  
  const derivedClipIdStudyIdMap = {};
  const derivedStudyList = [];

  const studiesRef = collection(dataRef, 'studies');
  const studies = await getDocs(studiesRef);
  let studiesData = {};
  for (const study of studies.docs) {
    const clipsRef = collection(study.ref, 'clips');
    const clips = await getDocs(clipsRef);
    const clipsData = {};
    for (const clip of clips.docs) {
      const clipData = clip.data();
      clipData.datetime = new Date(clipData.created).toISOString();
      clipData.id = clip.id;
      clipsData[clip.id] = clipData;

      derivedClipIdStudyIdMap[clip.id] = study.id;

    }
    const studyData = study.data();
    studyData.clips = clipsData;
    studiesData[study.id] = studyData;
  };
  
  data.studies = studiesData;
  data.derived = {
    clipIdStudyIdMap: derivedClipIdStudyIdMap,
    // studiesList: Object
  };
  return data;
};



/**
 * Transforms the firebase collection for study into the relevent form for the UI. 
 * @param studies 
 * @returns 
 */
const translateStudies = (studies:any[]) => {
  console.log(studies);
  return Object.keys(studies).map((studyId:string) => {
    return {
      id: studyId,
      name: studies[studyId].metadata.name,
      desription: studies[studyId].metadata.description,
      clipCount: Object.keys(studies[studyId].clips).length
    };

  })
}

export const launchStudy = (name, triggerKey, clipCount) => {
  return async (dispatch, getState) => {
    const data = {
      name,
      triggerKey: triggerKey.trim(),
      clipCount,
    }
    dispatch(startLoading(LOADING.LAUNCH_STUDY))
    // https://firebase.google.com/docs/functions/callable?gen=2nd#web-modular-api_4
    const launchStudy = getFirebaseFunction('launchStudy');
    launchStudy(data)
      .then((result) => {     
        // Refresh the data
        dispatch(initialize());
        navigate(`/app/study/view/${triggerKey}`);
      })
      .catch((error) => {
        if (error.code === 'functions/already-exists') {
          alert('The Trigger Key is already in use. Please choose another.');
        }
      })
      .finally(() => {
        dispatch(stopLoading(LOADING.LAUNCH_STUDY))
      });
  };
}

export const onboardingCreateTeam = (name) => {
  return async (dispatch, getState) => {
    const createTeamRPC = getFirebaseFunction('onboardingCreateTeam');
    createTeamRPC(name)
      .then((result) => {
        // The user should be coming from /onboarding which isn't in the 
        // reach router.
        window.location.href = "/app"  
      })
      .catch((error) => {

      })
  }
}

/**
 * Adds this user to a company via the company's invite code. 
 * @param name
 * @returns 
 */
export const onboardingJoinTeam = (code) => {
  return async (dispatch, getState) => {
    const createTeamRPC = getFirebaseFunction('onboardingJoinTeam');
    createTeamRPC(code)
      .then((result) => {
        // The user should be coming from /onboarding which isn't in the 
        // reach router.
        window.location.href = "/app"  
      })
      .catch((error) => {

      })
  }
}
