
/**
 * Defines the Loading State redux slice, actions, and reducer.
 * Loading States are used as a UI flag during the time-frame between the start and the end of an asynchronous action.
 * USAGE:
 *  dispatch(startLoading(LOADING.ENUM_VALUE));
 *  // Do async task
 *  dispatch(stopLoading(LOADING.ENUM_VALUE));
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOADING } from './contants';

export type LoadingState = {
  loading: { [key: number]: boolean };
};

const initialState: LoadingState = {
  loading: {
    [LOADING.ALL_DATA]: true
  },
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: initialState,
  reducers: {
    startLoading: (state, action: PayloadAction<number>) => {
      state.loading[action.payload] = true;
    },
    stopLoading: (state, action: PayloadAction<number>) => {
      delete state.loading[action.payload];
    },
  },
});

export const { startLoading, stopLoading } = loadingSlice.actions;
export const loadingReducer = loadingSlice.reducer;
